<template>
  <v-app>
    <v-container class="ma-0 pa-0" align-center justify-center fill-height>
      <v-layout row>
        <v-flex class="ma-0 pa-0">
          <Flipbook
            class="flipbook"
            :pages="pages"
            :pagesHiRes="pagesHiRes"
            :zooms="null"
            v-slot="flipbook"
            ref="flipbook"
          >
            <v-flex xs12 class="pt-2 action-bar text-xs-center">
              <font-awesome-icon
                class="ma-1 btn left"
                :class="{ disabled: !flipbook.canFlipLeft }"
                icon="chevron-circle-left"
                size="lg"
                @click="flipbook.flipLeft"
              ></font-awesome-icon>
              <span class="ma-1 font-weight-medium subheading">Page</span>
              <span class="font-weight-medium subheading">{{ flipbook.page }}</span>
              <span class="font-weight-medium subheading">&nbsp;of&nbsp;</span>
              <span class="mr-1 font-weight-medium subheading">{{ flipbook.numPages }}</span>

              <font-awesome-icon
                class="ma-1 btn right"
                :class="{ disabled: !flipbook.canFlipRight }"
                icon="chevron-circle-right"
                size="lg"
                @click="flipbook.flipRight"
              ></font-awesome-icon>
            </v-flex>
          </Flipbook>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import Flipbook from "flipbook-vue";

export default {
  name: "app",
  components: {
    Flipbook
  },
  data() {
    return {
      pages: [null, ...Array.from({ length: 20 }, (_, i) => require(`./assets/images/${i + 1}.png`))],
      pagesHiRes: [null, ...Array.from({ length: 20 }, (_, i) => require(`./assets/images/${i + 1}.png`))],
    }
  },
  computed: {
    canFlipLeft() {
      return this.$refs.flipbook.canFlipLeft;
    }
  },
  mounted() {
    window.addEventListener("keydown", function(ev) {
      let flipbook = this.$refs.flipbook;
      // return unless flipbook
      if (ev.keyCode === 37 && flipbook.canFlipLeft) {
        flipbook.flipLeft();
      }

      if (ev.keyCode === 39 && flipbook.canFlipRight) {
        flipbook.flipRight();
      }
      //flipbook.flipLeft() if ev.keyCode == 37 and flipbook.canFlipLeft
      //flipbook.flipRight() if ev.keyCode == 39 and flipbook.canFlipRight
    })
  }
};
</script>

<style>
html, body, #app {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333;
  color: #ccc;
  overflow: hidden;
  justify-content: center; 
}

.action-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-bar .btn {
}
.action-bar .btn svg {
  bottom: 0;
}
.action-bar .btn:not(:first-child) {
  margin-left: 10px;
}
.has-mouse .action-bar .btn:hover {
  color: #ccc;
  filter: drop-shadow(1px 1px 5px #000);
  cursor: pointer;
}
.action-bar .btn:active {
  filter: none !important;
}
.action-bar .btn.disabled {
  color: #666;
  pointer-events: none;
}
.flipbook {
  width: 90vw;
  height: 90vh;
}

.flipbook .viewport {
  width: 90vw;
  height: calc(100vh - 50px - 40px);
}
.font-weight-medium{
  display: none;
}
.flipbook .bounding-box {
  box-shadow: 0 0 20px #000;
}
</style>

